const serverTimezoneOffsetMs = globalThis.globalParams.serverTimezoneOffset * 60 * 1000,

/**
 * Returns the given date in the server's timezone, e.g. for the London timezone it adds an extra hour to the date
 * @param {Date | number | string} date
 * @returns {Date}
 */
 getDateInServerTimezone = (date) => {
	const localOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
	
	return new Date(new Date(date).getTime() - serverTimezoneOffsetMs + localOffsetMs);
},
      /**
       * @param {string | number | !Date} dateLike
       * @returns {Date}
       */
      getUTCDate = (dateLike) => {
	      let date = dateLike;
	
	      if (typeof dateLike === 'string' || typeof dateLike === 'number') {
		      date = new Date(dateLike);
	      }
	
	      return new Date(
		      date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()
	      );
      };

export {
	getDateInServerTimezone,
	getUTCDate
};