import $ from 'jquery';
import debounce from 'debounce';
import Dropdown from 'bootstrap/js/src/dropdown';
import {COOKIE_KEYS, MAX_STORED_PACKAGE_SEARCHES} from '#services/user/userConsts';
import userCookiesService from '../services/userCookiesService';
import {lazyLoad} from '../helpers/lazyLoadHelpers';
import ajaxService from '../services/ajaxService';

const searchInputField = $('#eventSelector'),
	  searchBoxDropDownContainer = $('#searchBoxDropDownList'),
	  fullEventsList = searchBoxDropDownContainer.find('.fullEventsList'),
	  eventsTypeContainers = searchBoxDropDownContainer.find('.popularEventsList, .listTitle, .mask'),
	  noResultsContainer = $('#eventsSearchNoResults, #eventsSearchNoResults .listTitle'),
	  otherElements = $('.fullEventsList, .arrangementList, .mask'),
	  arrangementListContainer = $('.arrangementList, .arrangementList .listTitle'),
	  eventResultsTitle = fullEventsList.find('.listTitle.clubList'),
	  leagueResultsTitle = $('.popularLeaguesList .leaguesListTitle'),
	  lastSearchesList = $('.lastSearchesList');

let scheduledSearchTrackingTimer;

class HeaderSearch {
	/** @type {?jQuery} */
	originalArrangementList = null;
	
	/** @type {?jQuery} */
	originalLeaguesList = null;
	
	/** @type {?JQuery.jqXHR} */
	fetchReq = null;
	
	constructor() {
		this._renderLastSearchesList();
		this.initEvents();
	}

	initEvents() {
		// disallow hiding the dropdown when clicking on the input field again
		const eventSelector = document.getElementById('eventSelector');

		let searchDropdown = null;

		if (eventSelector) {
			searchDropdown = Dropdown.getOrCreateInstance(eventSelector);
			eventSelector.addEventListener('hide.bs.dropdown', (ev) => {
				if (searchInputField.filter(':focus').length) {
					ev.preventDefault();
				}
			});
		}

		searchInputField
			.one('focus', () => {
				const search = searchInputField.val();

				// if the input already has content, do the filtering
				if (search.trim()) {
					this._handleSearch(search);
				}
				
				dataLayer.push({
					'event'        : 'Find Your Club',
					'eventCategory': 'User-Action',
					'eventAction'  : 'Event_Find_Your_Club',
					'eventLabel'   : 'Website'
				});
			})
			.on('focus', () => {
				if (searchDropdown && !searchBoxDropDownContainer.is(':visible')) {
					searchInputField.trigger('click.bs.dropdown');
				}
			})
			.on(
				'input',
				debounce(
					() => {
						this._handleSearch(searchInputField.val());
						this._scheduleSearchTracking(searchInputField.val());
					},
					100
				)
			)
			.on('keypress', (ev) => {
				// when pressing enter select the best result
				if (ev.which === 13) {
					const [bestResult] = this.getEventRows().filter(':visible'); // eslint-disable-line unicorn/prefer-array-find

					if (bestResult) {
						bestResult.click();
					}
				}
			});

		lastSearchesList.on(
			'click',
			'.itemName',
			(ev) => {
				const $clubName = $(ev.currentTarget);

				this._setSearchInputFieldValue($clubName.text().trim());
				ev.preventDefault();
			}
		);

		searchBoxDropDownContainer.on(
			'mousedown',
			'.itemRow',
			() => {
				this.storePackageSearch(searchInputField.val());
			}
		);
	}
	
	_handleSearch(search) {
		this._renderLastSearchesList();

		if (search.trim().length) {
			// if there is an ongoing request before starting a new, we won't need it's result anymore
			if (this.fetchReq) {
				this.fetchReq.abort();
			}

			this._doSearchReq(search);
		} else {
			this._handleSearchRequestError();
		}
	}

	_doSearchReq(search) {
		this.fetchReq = this._fetchResultsList(search);

		this.fetchReq
			.then(this._handleSuccessResponse.bind(this))
			.catch(this._handleSearchRequestError.bind(this));

		eventsTypeContainers.hide();
		this.clearArrangementListSearchContainer();
		searchBoxDropDownContainer.addClass('searchActive');
	}

	_handleSearchRequestError(req) {
		// don't do anything when a request was aborted
		if (req && req.statusText === 'abort') {
			return;
		}

		eventsTypeContainers.show();
		noResultsContainer
			.add(eventResultsTitle)
			.add(arrangementListContainer)
			.hide();

		this.showOriginalEventRows();
		this.showOriginalLeagueRows();
		searchBoxDropDownContainer.removeClass('searchActive');
	}

	_handleSuccessResponse(res) {
		this.fetchReq = null;
		this._updateResultsList(res);
	}

	_updateResultsList(res) {
		const {eventsList, leaguesList, arrangements} = res,
			  gotEvent 					              = !!eventsList && eventsList.length > 0,
			  gotLeague 					          = !!leaguesList && leaguesList.length > 0,
		      hasResult                               = gotEvent || gotLeague || arrangements.length > 0;

		eventResultsTitle.toggle(gotEvent);
		leagueResultsTitle.toggle(gotLeague);

		if (this.originalArrangementList) {
			this.getEventRows().remove();
		} else {
			this.originalArrangementList = this.getEventRows().detach();
		}
		
		if (this.originalLeaguesList) {
			this.getLeagueRows().remove();
		} else {
			this.originalLeaguesList = this.getLeagueRows().detach();
		}
		
		if (gotEvent || gotLeague) {
			if (gotEvent) {
				eventResultsTitle.after(eventsList);
			}
			
			if (gotLeague) {
				leagueResultsTitle.after(leaguesList);
			}
			
			this.reInitLazyLoad();
		}

		this._renderArrangementsList(arrangements);
		this.handleNoResultMsg(hasResult);
	}

	_renderArrangementsList(arrangements) {
		if (arrangements.length) {
			arrangementListContainer.show();
			$('.arrangementList .list').append(
				arrangements.map(this._createArrangementRow.bind(this))
			);
		} else {
			arrangementListContainer.hide();
		}
	}

	_createArrangementRow(arrangeResult) {
		let insertSymbol = arrangeResult.arrangementsData.length > 1;

		const arrangeRowHtml = arrangeResult.arrangementsData.map((arrange, index) => {
			// insert the symbol only after the first arrangement
			insertSymbol &&= index === 0;

			return this._createArrangementRowContent(arrange, insertSymbol);
		});

		return `
				<div class="itemRowHolder">
					<a class="dropdown-item itemRow" href="${arrangeResult.seoLabel}">
						${arrangeRowHtml.join('')}
					</a>
				</div>
			`;
	}

	_createArrangementRowContent(arrange, insertSymbol) {
		const venueAndCityRow 	= this._createVenueAndCityRow(arrange),
			  symbolHtml 		= insertSymbol ? '<div class="itemName symbol">&</div>' : '';

		return `
				<div class="itemName">
					<span class="title">${arrange.title}</span> - <span class="location">${venueAndCityRow}</span>
				</div>
				<div class="date">${arrange.date}</div>
				${symbolHtml}
			`;
	}

	_createVenueAndCityRow(arrange) {
		return arrange.venueName && arrange.cityName
				? `${arrange.venueName}, ${arrange.cityName}`
				: arrange.venueName || arrange.cityName;
	}

	/**
	 * @param {!Array<string>} packageSearches
	 * @private
	 */
	_renderLastSearchesList(packageSearches = userCookiesService.getLastPackageSearches()) {
		if (!packageSearches.length || searchInputField.val().length) {
			lastSearchesList.hide();

			return;
		}

		lastSearchesList
			.find('.list')
			.html(
				packageSearches
					.map(this._createPreviousSearchRow.bind(this))
					.join('')
			);

		lastSearchesList.show();
	}

	/**
	 * @param {string} search
	 * @returns {string}
	 * @private
	 */
	_createPreviousSearchRow(search) {
		return `
			<div class="itemRowHolder">
				<a class="dropdown-item itemRow" href="#">
					<div class="itemName">
						<span>${search}</span>
					</div>
				</a>
			</div>
		`;
	}

	/**
	 * @param {string} previousSearch
	 * @private
	 */
	_setSearchInputFieldValue(previousSearch) {
		searchInputField
			.val(previousSearch)
			.trigger('input')
			.trigger('focus');
	}

	clearArrangementListSearchContainer() {
		$('.arrangementList .list').empty();
	}

	/**
	 * @param {string} search
	 * @returns {!JQuery.jqXHR}
	 * @private
	 */
	_fetchResultsList(search) {
		return ajaxService.sendGet(
			`arrangement/autocomplete/header_search/?search=${encodeURI(search)}`,
			{
				'withAbTests': false
			}
		);
	}

	handleNoResultMsg(hasResult) {
		noResultsContainer.toggle(!hasResult);
		otherElements.toggle(hasResult);
	}

	showOriginalEventRows() {
		if (this.originalArrangementList) {
			this.getEventRows().remove();
			eventResultsTitle.after(this.originalArrangementList);
			$('.fullEventsList').show();
			this.originalArrangementList = null;
		}
	}

	showOriginalLeagueRows() {
		if (this.originalLeaguesList) {
			this.getLeagueRows().remove();
			leagueResultsTitle.after(this.originalLeaguesList);
			this.originalLeaguesList = null;
		}
	}
	
	/**
	 * @returns {JQuery}
	 */
	getEventRows() {
		return fullEventsList.find('.itemRow');
	}
	
	/**
	 * @returns {JQuery}
	 */
	getLeagueRows() {
		return $('.popularLeaguesList .itemRow');
	}
	
	reInitLazyLoad() {
		lazyLoad({
			'elements_selector': '#searchBoxDropDownListCont .lazy'
		});
	}

	/**
	 * @param {string} search
	 * @private
	 */
	_scheduleSearchTracking(search) {
		if (scheduledSearchTrackingTimer) {
			clearTimeout(scheduledSearchTrackingTimer);
		}

		if (!search.trim().length) {
			return;
		}

		scheduledSearchTrackingTimer = setTimeout(
			this._trackSearch.bind(this, search),
			1000
		);
	}
	
	/**
	 * @param {string} search
	 */
	storePackageSearch(search) {
		if (!search.trim().length || search.length > 50) {
			return;
		}
		
		const packageSearches = userCookiesService.getLastPackageSearches();
		
		if (packageSearches.includes(search)) {
			return;
		}
		
		packageSearches.unshift(search);
		packageSearches.length = Math.min(packageSearches.length, MAX_STORED_PACKAGE_SEARCHES);
		
		userCookiesService.storeInUserData(COOKIE_KEYS.PACKAGE_SEARCHES, packageSearches);
	}
	
	/**
	 * @param {string} search
	 * @private
	 */
	_trackSearch(search) {
		dataLayer.push({
			'packageSearch': search
		});
	}

}

export default HeaderSearch;