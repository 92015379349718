import {getDateInServerTimezone} from '../helpers/dateHelpers';

class CountDown {
	constructor(endDate, elements) {
		this.elements      = elements;
		this.countDownDateTime = getDateInServerTimezone(endDate).getTime();
		
		this.countDown();
	}
	
	countDown() {
		if (this.countDownDateTime > Date.now()) {
			this.renderTimeLeft(this.countDownDateTime);
			
			const blockUpdateInterval = setInterval(() => {
				this.renderTimeLeft();
				
				if (this.countDownDateTime < Date.now()) {
					clearInterval(blockUpdateInterval);
				}
			}, 1000);
		}
	}
	
	renderTimeLeft() {
		const now     = Date.now(),
		      oneHour = 1000 * 60 * 60,
		      oneMin  = 1000 * 60,
		      diff    = this.countDownDateTime - now,
		      hours   = Math.floor(diff / oneHour),
		      minutes = Math.floor((diff % oneHour) / oneMin),
		      seconds = Math.floor((diff % oneMin) / 1000);
		
		this.elements.forEach((element) => {
			element.textContent = `${hours}h : ${minutes}m : ${seconds}s`;
		});
	}
}

export {
	CountDown
};