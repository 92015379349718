import {CountDown} from '../components/countDown';

const initCountDown = () => {
	const counterElements = document.querySelectorAll('.promotionCounter');
	
	if (counterElements.length !== 0) {
		new CountDown(
			new Date('2024-02-06 08:00:00'),
			counterElements
		);
	}
};

export {initCountDown};